import axios from '@/apis/axios';
import { getAccessToken } from '@/lib/api';

/**
 * 申込書取得
 */
export const getApplicationFormList = async () => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get('/agencies/application_forms/', {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込書マスター登録
 */
export const postApplicationFormMaster = async (
  file,
  applicationFormDetails,
  coordinateManagementList,
  applicationFormPrintLocation
) => {
  const accessToken = await getAccessToken();

  const form = new FormData();
  form.append('files[0]', file);
  form.append('applicationFormDetails', JSON.stringify(applicationFormDetails));
  form.append(
    'coordinateManagementList',
    JSON.stringify(coordinateManagementList)
  );
  form.append(
    'applicationFormPrintLocation',
    JSON.stringify(applicationFormPrintLocation)
  );

  const response = await axios
    .post(`/agencies/application_forms/`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'access-token': accessToken,
      },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込書マスター取得
 */
export const getApplicationFormMaster = async applicationFormId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/application_forms/${applicationFormId}`, {
      responseType: 'blob',
      dataType: 'application/pdf',
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  if (!response) return;

  // typeの実態がapplication/octet-streamになっているためpdfに書き換える
  const blob = new Blob([response.data], { type: 'application/pdf' });

  return blob;
};

/**
 * 申込書マスター更新
 */
export const putApplicationFormMaster = async (
  applicationFormDetails,
  applicationFormId
) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/application_forms/${applicationFormId}`,
      { applicationFormDetails },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込書情報取得
 */
export const getApplicationFormData = async applicationFormId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/application_forms/data/${applicationFormId}`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 座標管理マスタ取得
 */
export const getCoordinateManagementList = async applicationFormId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(
      `/agencies/application_forms/coordinate_managements/${applicationFormId}`,
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 座標管理マスタ修正
 */
export const putCoordinateManagement = async (
  applicationFormId,
  coordinateManagement
) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/application_forms/coordinate_managements/${applicationFormId}`,
      { coordinateManagement },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 座標管理マスタ追加
 */
export const addCoordinateManagement = async (
  applicationFormId,
  coordinateManagement
) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .post(
      `/agencies/application_forms/coordinate_managements/${applicationFormId}`,
      { coordinateManagement },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 座標管理マスタ削除
 */
export const deleteCoordinateManagement = async (
  applicationFormId,
  coordinateManagementId
) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .delete(
      `/agencies/application_forms/coordinate_managements/${applicationFormId}/${coordinateManagementId}`,
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 座標管理マスタ表示順更新
 */
export const putCoordinateManagementReOrder = async (
  applicationFormId,
  coordinateManagementList
) => {
  const accessToken = await getAccessToken();

  const response = await axios
    .put(
      `/agencies/application_forms/coordinate_managements/${applicationFormId}/re_order`,
      { coordinateManagementList },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 申込書印字位置更新
 */
export const postApplicationFormPrintPosition = async (
  applicationFormId,
  updatePosition
) => {
  const accessToken = await getAccessToken();

  const data = updatePosition;
  data.applicationFormId = applicationFormId;

  const response = await axios
    .post(
      `/agencies/application_forms/update_printing_position`,
      { ...data },
      {
        headers: { 'access-token': accessToken },
      }
    )
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 種目別適用開始日一覧取得
 */
export const getApplicationFormStartDateList = async insuranceTypeId => {
  const accessToken = await getAccessToken();

  const response = await axios
    .get(`/agencies/application_forms/${insuranceTypeId}/start_dates`, {
      headers: { 'access-token': accessToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};
